import { LayoutProps } from "./_model";
export default function Layout(props: LayoutProps) {
  return (
    <div className="flex flex-col bg#p-bg w-full h-full items-center justify-center">
      <div className="flex flex-col w-30% h-fit bg-white items-center justify-center rounded-xl">
        {props.children}
      </div>
    </div>
  );
}
